import React, { useState } from 'react'
import I18n from '../components/UI/I18n'
import Title from './UI/Title'
import Option from './Option'


const Options = ({ current, event, options, width, addProduct, removeProduct, setLoad, disabled }) => {
  const [selected, setSelected] = useState(options.map((option) => option.default))

  return (
    <React.Fragment>
      <div className="w-full sm:w-128 lg:w-full text-2xl mt-10 lg:my-10 mx-auto">
        <Title variant="extra-large">
          <img src="/lalalab/coeurs.png" className="absolute w-16 -mt-6" style={{ marginLeft: '7rem' }}/>
          <div className="z-10"><I18n>app-redesign.options.title</I18n></div>
        </Title>
      </div>
      <div className={`flex flex-col lg:flex-row justify-between cursor-pointer lg:-mx-3`}>
        {event.stripeCache && options.sort((a, b) => b.name.localeCompare(a.name)).map((option, i) => (
          !option.shipping &&
          <Option
            key={i}
            current={current}
            event={event}
            option={option}
            options={options.length}
            addProduct={addProduct}
            removeProduct={removeProduct}
            selected={selected[i]} setLoad={setLoad}
            disabled={disabled}
            onClick={() => setSelected(prevState => prevState.map((opt, index) => index === i ? !opt : opt))} />
        ))}
      </div>
    </React.Fragment>
  )
}

export default Options
