import React, { useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Checkbox from './UI/Checkbox'
import Price from './UI/Price'
import { FiDownload, FiX } from 'react-icons/fi'
import Popup from './UI/Popup'
import { getStocks } from '../api'
import Info from './UI/Info'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#2e3238', borderColor: '#e3e5e8', fontFamily: `'Nunito'`, fontWeight: '300', fontSmoothing: 'antialiased', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', fontSize: '16px', '::placeholder': { fontWeight: '300', color: '#777777' }
    },
    invalid: { color: '#fa755a', iconColor: '#fa755a' }
  }
}

const status = {
  draft: <span className="border border-orange text-orange px-4 rounded-sm"><I18n>form.payment.draftStatus</I18n></span>,
  paid: <span className="border border-blue text-blue px-4 rounded-sm"><I18n>form.payment.paidStatus</I18n></span>,
  open: <span className="border border-orange text-orange px-4 rounded-sm"><I18n>form.payment.openStatus</I18n></span>,
  void: <span className="border border-gray-500 text-gray-500 px-4 rounded-sm"><I18n>form.payment.voidStatus</I18n></span>
}

export default function PayEvent({ event, upcoming, tryPromo, deletePromo, finalize, advance, succeeded, payTrigger, locked }) {

  const [show, setShow] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [showPromo, setShowPromo] = useState(false)
  const [promo, setPromo] = useState('')
  const [cardError, setCardError] = useState(true)
  const [cgu, setCgu] = useState(false)
  const [outOfStockDates, setOutOfStockDates] = useState([])
  const [outOfStock, setOutOfStock] = useState(false)
  const invoices = event.stripeCache.invoices
  const intents = event.stripeCache.intents
  const openInvoice = invoices && invoices.find(i => i.status === 'open')
  const paidInvoice = invoices && invoices.find(i => i.status === 'paid')
  const currentAdvance = intents && intents.find(i => i.description === 'Acompte' && ['requires_action', 'requires_payment_method'].includes(i.status))
  const current = upcoming || invoices.find(i => i.status === 'draft') || invoices.find(i => i.status === 'open')

  useEffect(() => {
    getStocks()
      .then(data => setOutOfStockDates(data.map(e => {
        return { from: e.from, to: e.to, remaining: e.remaining > 0 ? true : false }
      })))
  }, [])
  useEffect(() => {
    if (event.date) {
      outOfStockDates.map(date => {
        if (date.from <= event.date && date.to >= event.date && !date.remaining && (current?.pre_payment_credit_notes_amount === 0 && !paidInvoice)) {
          setOutOfStock(true)
        }
      })
    }
  }, [outOfStockDates])

  useEffect(() => {
    // if (openInvoice)
    //   setShow('total')
    if (outOfStock && current)
      setShow(true)
    if (currentAdvance)
      setShow('advance')
    if (payTrigger)
      payTrigger.click = () => setShow('total')
  }, [outOfStock])

  const trigger = async () => {
    if (current.total == 0) {
      await finalize()
      await succeeded()
      setShow(false)
      return
    }
    const card = elements.getElement(CardElement)
    const { stripeCache } = show === 'advance' ? await advance() : await finalize()
    const secret = stripeCache.intents.find(i => ['requires_action', 'requires_payment_method'].includes(i.status)).client_secret
    try {
      const result = await stripe.confirmCardPayment(secret, { payment_method: { card }, setup_future_usage: !event.dealer ? 'off_session' : undefined })
      if (result.error) throw result.error.message
      setCardError(null)
      if (result.paymentIntent.status === 'succeeded') {
        if (show === 'advance')
          await advance()
        setShow(false)
        succeeded()
      }
    } catch (e) {
      setCardError(`${e}`)
      throw e
    }
  }
  const Promo = () => {
    if (current && current.discount) {
      return (
        <div className="w-full text-sm text-gray-600 text-right mt-1 flex justify-end items-center">
          <div>{current.discount.coupon.name}&nbsp;- <Price price={current.total_discount_amounts[0]} /></div>
          <FiX onClick={deletePromo} className="w-5 h-5 -mt-1 ml-1 cursor-pointer"/>
        </div>
      )
    }
    else if (showPromo)
      return (
        <div className="flex justify-end items-center w-full mt-2">
          <input type="text" className="form-input w-32 py-1 text-xs mr-2 text-gray-500" value={promo} onChange={e => setPromo(e.target.value)} autoFocus />
          <Button variant="secondary" onClick={() => tryPromo(promo).then(e => setCardError()).catch(e => setCardError(e))} className={`mx-3`} style={{ margin: 0, height: 24 }} disabled={promo.length < 4}>Ok</Button>
        </div>
      )
    else if (!showPromo)
      return (
        <u className="block w-full text-sm text-gray-600 text-right cursor-pointer mt-1" onClick={() => setShowPromo(true)}>
          <I18n>form.payment.promo</I18n>
        </u>
      )
    else return null
  }
  if (current || openInvoice)
    return (
      <>
        {current.amount_remaining > 28000 && current.pre_payment_credit_notes_amount <= 10000 && !locked &&
          <div className="flex flex-col justify-center items-center">
            <Button className={`mx-1 lg:mx-2 my-2 lg:w-56 leading-tight`} variant="secondary" onClick={() => setShow('advance')}>
              <div className="">
                Réserver pour <Price price={15000} loading={current.loading} />
              </div>
            </Button>
            <div className="normal-case text-sm opacity-75 whitespace-no-wrap -mt-1" style={{ fontFamily: 'Roboto', letterSpacing: 0 }}>
              et payer <Price price={current.amount_remaining - 15000} loading={current.loading} /> plus tard
            </div>
          </div>
        }
        {current.pre_payment_credit_notes_amount <= 10000 && !locked &&
          <div className="flex flex-col justify-center items-center">
            <Button className={`mx-1 lg:mx-2 my-2 lg:w-56 leading-tight`} variant="secondary" onClick={() => setShow('total')}>
              <div className="">
                Payer <Price price={current.amount_remaining} loading={current.loading} />
              </div>
            </Button>
            <div className="normal-case text-sm opacity-75 whitespace-no-wrap -mt-1" style={{ fontFamily: 'Roboto', letterSpacing: 0 }}>
              dépéchez-vous, ça part vite
            </div>
          </div>
        }
        {current.pre_payment_credit_notes_amount > 10000 &&
          <div className="flex flex-col justify-center items-center">
            <Button className={`mx-1 my-2 leading-tight`} variant="secondary" onClick={() => setShow('total')}>
              <div className="">
                <Price price={current.amount_remaining} loading={current.loading} /> restant
              </div>
            </Button>
            <div className="normal-case text-sm opacity-75 whitespace-no-wrap -mt-1" style={{ fontFamily: 'Roboto', letterSpacing: 0 }}>
              Vous avez déjà payé <Price price={current.pre_payment_credit_notes_amount} loading={current.loading} />
            </div>
          </div>
        }
        {show && outOfStock && (
          <Popup title={<I18n>form.payment.titleOutOfStock</I18n>} small close={() => setShow(false)}>
            <p><I18n>form.payment.outOfStock</I18n></p>
          </Popup>
        )}
        {(show && !outOfStock && locked && current.pre_payment_credit_notes_amount === 0) ? (
          <Popup title={<I18n>form.payment.titleOutOfStock</I18n>} small close={() => setShow(false)}>
            <p><I18n>app-redesign.header.booking</I18n></p>
          </Popup>) : (show && !outOfStock && < Popup title={show === 'advance' ? 'Régler un acompte' : 'Régler le solde'} small close={() => setShow(false)}>
            <div className="max-w-screen-sm flex flex-col justify-center items-center">
              {current.amount_remaining == 0 &&
                <>
                  <div className="mb-4 text-gray-600"><I18n>form.payment.totalInvoice</I18n> <Price price={current.total} loading={current.loading} /></div>
                  <Button className={`mx-3`} variant="secondary" onClick={trigger}><I18n>form.payment.confirm</I18n></Button>
                </>
              }
              {current.amount_remaining > 0 &&
                <>
                  <CardElement
                    className="form-input mt-6 w-full"
                    style={{ paddingTop: 15 }}
                    onChange={e => { console.log(e); setCardError(e.error ? e.error.message : e.complete ? false : 'Incomplet') }}
                    options={CARD_ELEMENT_OPTIONS} />
                  {current.pre_payment_credit_notes_amount > 0 ? '' : <Promo />}
                  <div className="flex items-center w-full my-8 text-sm text-left leading-tight lg:text-base">
                    <Checkbox checked={cgu} onClick={() => setCgu(!cgu)} className="mr-4" />
                    <div className="text-gray-500"><I18n html>form.payment.cgu</I18n></div>
                  </div>
                  {cardError && ![true, 'Incomplete'].includes(cardError) &&
                    <div className="leading-none font-light text-sm text-red opacity-75 mb-2">
                      {cardError}
                    </div>
                  }
                  <Button className={`mx-3`} variant="secondary" onClick={trigger} disabled={cardError || !cgu}>Payer&nbsp;<Price price={show === 'advance' ? 15000 : current.amount_remaining} loading={current.loading} /></Button>
                  {show === 'advance' &&
                    <>
                      <Info className="mt-6">Le solde de votre réservation est à <strong>régler impérativement 7 jours</strong> avant votre événement</Info>
                      <Info warning><strong>Si vous avez un code promo, utilisez le maintenant.</strong> Il ne sera plus possible d'en utiliser un après avoir réglé l'acompte.</Info>
                    </>
                  }
                </>
              }
            </div>
          </Popup >)
        }
      </>
    )
  if (!upcoming && paidInvoice)
    return (
      <React.Fragment>
        <div className="flex flex-col justify-center items-center h-full text-center leading-tight p-1">
          <div className="font-thin text-base text-gray-500 mb-1"><I18n>form.payment.booked</I18n></div>
          <Button
            className={`mx-3`} variant="secondary"
            onClick={() => setShow('details')}><I18n>form.payment.details</I18n></Button>
        </div>
        {show &&
          <Popup title="Détails du paiement" small={true} close={() => setShow(false)}>
            {invoices.map((i, index) => (
              <div key={index} className="w-full mx-auto leading-normal mb-4 text-sm">
                <div className="flex justify-between items-center text-base">
                  <div className="font-bold flex items-center">
                    {i.number ? `Invoice ${i.number}` : `En attente`}
                    {i.paid && <Button variant="secondary" Icon={FiDownload} onClick={() => window.open(i.hosted_invoice_url, '_blank')} className="ml-3"></Button>}
                  </div>
                  {status[i.status]}
                </div>
                <div className=" w-full">
                  {i.lines.data.map((l, index) => (
                    <div key={index} className="w-full flex justify-between">
                      <div>{l.description}</div>
                      <Price price={l.price.unit_amount} />
                    </div>
                  ))}
                </div>
                <div className="text-right">
                  {i.tax &&
                    <div className="flex justify-end">
                      <div className="mr-4 font-bold"><I18n>form.payment.vat</I18n></div>
                      <Price className="w-24" price={i.tax} />
                    </div>
                  }
                  <div className="flex justify-end">
                    <div className="mr-4 font-bold"><I18n>form.payment.subtotal</I18n></div>
                    <Price className="w-24" price={i.subtotal} />
                  </div>
                  {i.total_discount_amounts[0] && i.total_discount_amounts[0].amount > 0 &&
                    <div className="flex justify-end">
                      <div className="mr-4 font-bold"><I18n>form.payment.discount</I18n></div>
                      <Price className="w-24" price={-i.total_discount_amounts[0].amount} />
                    </div>
                  }
                  <div className="flex justify-end">
                    <div className="mr-4 font-bold"><I18n>form.payment.total</I18n></div>
                    <Price className="w-24" price={i.total} />
                  </div>
                </div>
              </div>
            ))}
          </Popup>
        }
      </React.Fragment>
    )
  return null
}
